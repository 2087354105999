<template>
    
      <b-card-group>
          <b-card
            header-tag="header"
            img-src="https://donaclara.com.br/site/www/app-assets/images/logo.png"
            align="center"
            img-width="30%"
            img-height="40%"
            footer="© 2021 Copyright Colégio Dona Clara"
            footer-tag="footer"
            icon="exclamation-triangle"
            title=""
            style="max-width: 35rem; margin-top: 8%"
            class=" mx-auto"
            align-v="center"
          >
            <b-container>
              <slot name="boxunico" />
            </b-container>
          </b-card>
      </b-card-group>
    
</template>

<script>

export default {
  name: 'LoginTpl',
}
</script>