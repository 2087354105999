<template>
  <div>
    <login-tpl>
      <span slot="boxunico">
        <div id="customBtn" @click="handleClickSignIn">
          <span class="icon"></span>
          <span class="buttonText">Login com o @donaclara</span>
        </div>
      </span>
    </login-tpl>
  </div>
</template>

<script>
import LoginTpl from '@/tpls/LoginTpl.vue'
    
export default {
  name: 'Login',
  data () {
    return {
      isInit: false,
      isSignIn: false,
      profile: false,
      usuarioEmail: false,
    }
  },

  components:{
    LoginTpl
  },  

  methods: {

    handleClickSignIn(){
      this.$gAuth.signIn()

      .then(user => {
        this.isSignIn = this.$gAuth.isAuthorized
        this.$store.commit('setUsuario',user.getAuthResponse());
        sessionStorage.setItem('usuario', JSON.stringify(user.getAuthResponse()));
        this.profile = user.getBasicProfile();
        this.$store.commit('setUsuarioEmail', this.profile.getEmail());
        sessionStorage.setItem('usuarioEmail', JSON.stringify(this.profile.getEmail()));
        //console.log(user);
        if (this.isSignIn) {
          this.$router.push('/verify');
        }
      })
        
        //console.log(user.getBasicProfile());
        //console.log(this.profile.getEmail());
        //this.str = user.ft.Qt;
        //
     
      .catch(error  => {
        this.$router.push('/').catch(()=>{});
        // On fail do something
      })
    },

  },

    created(){
/*      
    let guser = this.$store.getters.getUsuario;
    if (guser){
      this.$router.push('/verify');
    }
    */
  },

    mounted(){
    let that = this
    let checkGauthLoad = setInterval(function(){
      that.isInit = that.$gAuth.isInit
      that.isSignIn = that.$gAuth.isAuthorized
      if(that.isInit) clearInterval(checkGauthLoad)
    }, 1000);
  },

}

</script>
<style scoped>

  #login {
    background-color: #1b378a;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background-image: url("https://donaclara.com.br/site/www/app-assets/images/ead_login.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;  
  }

  #customBtn {
    display: inline-block;
    background: white;
    color: #444;
    width: 235px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
  }

  #customBtn:hover {
    cursor: pointer;
  }
  span.label {
    font-family: serif;
    font-weight: normal;
  }
  span.icon {
    background: url('https://developers.google.com/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
  }
  span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 3px;
    padding-right: 15px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;
  }


</style>